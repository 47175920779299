import React from 'react';
import { Layout } from 'antd';
import gabeian from '../../images/gabeian.png'


const { Footer } = Layout;

const footerStyle = {
  minWidth: 1160,
  textAlign: 'center',
  color: '#fff',
  marginTop: 20,
  backgroundColor: '#0e2a01',
};

const FooterCom = () =>{

  return (
    <Footer style={footerStyle}>
      <div>
        中国营养师网 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主办单位：中国营养学会
      </div>
      <a href="https://beian.miit.gov.cn/" style={{color: '#337ab7'}}>京ICP备05018212号-5</a> 
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010216" >
        <p style={{float:'center',height:20, textAlign: 'center',lineHeight:'20px',margin: '0px 0px 0px 5px', color:'#939393'}}>
          <img src={gabeian} style={{float:'center', verticalAlign:'top'}}/>京公网安备 11010202010216号
        </p>
      </a>
    </Footer>
  )
}

export default FooterCom