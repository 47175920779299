import axios from 'axios'

// const ip = 'http://47.104.194.45:9094'
// const ip = 'http://192.168.0.16:9094'
// const ip ='https://coolsdev.oramiro.com/ht'
const ip ='https://dietitian.org.cn/dietitianbg'

const Axios = async (url:string, upData:any) =>{
  console.log('upData', upData)
  let token = sessionStorage.getItem('token')
  let headers
  if(token){
    headers = {
      'content-type': 'application/json',
      'token': token
    }
  }else{
    headers = {'content-type': 'application/json'}
  }
  console.log(sessionStorage.getItem('token'));
  let options:any = {
    method:'POST',
    url: ip+url,
    data: upData,
    // mode: 'cors',
    // // credentials: 'include',
    // timeout: 60000,
    headers,
  }
  return axios(options)
  .then((res)=>{
    // console.log(res)
    return res
  }).catch((err)=>{
    return err
  })
}

export default Axios