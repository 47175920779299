
import Home from './pages/home/home'
import Content from './pages/content/content'

export const router = [{
  path: '/home',
  name: '首页',
  component: <Home />
},{
  path: '/newsAndAnnouncement',
  key: 'newsAndAnnouncement',
  name: '资讯中心',
  component: <Content />
},
// {
//   path: '/policyStandards',
//   key: 'policyStandards',
//   name: '指南标准',
//   component: <Content />
// },
{
  path: '/nutritionTools',
  key: 'nutritionTools',
  name: '营养师工具',
  component: <Content />
},{
  path: '/abilityPromotion',
  key: 'abilityPromotion',
  name: '继续教育',
  component: <Content />
},{
  path: 'https://www.cnsedu.net/skill/index',
  key: 'publicNutritionist',
  name: '公共营养师',
  component: <Content />,
  external:true
},
// {
//   path: '/videoZone',
//   key: 'videoZone',
//   name: '视频专区',
//   component: <Content />
// },
{
  path: 'https://www.crdietitian.org/',
  key: 'registeredDietitian',
  name: '注册营养师',
  component: <Content />,
  external:true
}]



