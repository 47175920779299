import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { router } from '../../router'
import { List } from 'antd';
import { HomeFilled } from '@ant-design/icons';

import emptyImg from '../../images/logo.deddde2.png'
import detailBanner from '../../images/detail_banner.png'

import hangye from '../../images/hangye.png'
import guoji from '../../images/guoji.png'
 
import { 
  newsData, 
  NoticeData,

  policy,
  expertConsensus,
  technicalStandard,

  continuingEducation,
  teacherTraining,
  nutritionSciencePopularization, 

  assessmentConsultation,

  DRIs,
  dietaryGuidelines,
  other,


  videoDietaryGuidelines,
  videoDRIS,

} from '../../components/mokeData'

import Axios from '../../components/BaseRequest.ts'

import './index.less'
import './quill.snow.css'


const Content = () =>{
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [findRouter, setFindRouter] = useState({})
  const [selectTag, setSelectTag] = useState({})
  const [selectArticle, setSelectArticle] = useState({})
  const [articleIndex, setArticleIndex] = useState(0)

  const [selectAllData, setSelectAllData] = useState([])
  

  const [tagData, setTagData] = useState({
    'newsAndAnnouncement':[
      // {name: '新闻', key: 'news', data: newsData},
      // {name: '公告', key: 'Notice', data: NoticeData}
      {name: '行业动态', key: 'Notice', data: [], defaultImg: hangye},
      {name: '国际资讯', key: 'news', data: [], defaultImg: guoji},
    ],
    'policyStandards':[
      // {name: '政策法规', key: 'policy', data: policy},
      // {name: '专家共识', key: 'expertConsensus', data: expertConsensus},
      // {name: '技术标准', key: 'technicalStandard', data: technicalStandard},
      {name: '政策法规', key: 'policy', data: []},
      {name: '专家共识', key: 'expertConsensus', data: []},
      {name: '技术标准', key: 'technicalStandard', data: []},
    ],
    'abilityPromotion':[
      // {name: '继续教育', key: 'continuingEducation', data: continuingEducation},
      // {name: '师资进修', key: 'teacherTraining', data: teacherTraining},
      // {name: '营养科普', key: 'nutritionSciencePopularization', data: nutritionSciencePopularization},
      {name: '继续教育', key: 'continuingEducation', data: []},
      {name: '师资进修', key: 'teacherTraining', data: []},
      {name: '营养科普', key: 'nutritionSciencePopularization', data: []},
    ],
    'publicNutritionist':[
      {name: '成绩查询', key: '', link: 'http://score.dietitian.net/scoreService/query'},
      {name: '证书查询', key: '', link: 'http://cert.dietitian.net/certService/query'},
      {name: '考核咨询', key: 'assessmentConsultation', data: assessmentConsultation},
      {name: '考试报名', key: '', link: 'http://sign.dietitian.net/login'}
    ],
    'nutritionTools':[
      // {name: 'DRIs', key: 'DRIs', data: []},
      // {name: '膳食指南', key: 'dietaryGuidelines', data: dietaryGuidelines},
      {name: '营养数据信息资源库', key: '', link: ''},
      {name: '营养与健康信息共享平台', key: '', link: ''},
      {name: '膳食指南工具包', key: '', link: 'https://www.cnsedu.net/tool/toolkit'},
      {name: '全民营养周工具包', key: '', link: 'https://www.cnsoc.org/background/'},
      {name: 'NutriData', key: '', link: 'https://www.nutridata.cn/home'},
      {name: '配餐工具', key: '', notes: 'https://www.dyhomedr.com/#/user/public'},
      // {name: '其他', key: 'other', data: other},
    ],
    'videoZone':[
      // {name: '膳食指南', key: 'videoDietaryGuidelines', data: videoDietaryGuidelines},
      // {name: 'DRIS', key: 'videoDRIS', data: videoDRIS},
      {name: '膳食指南', key: 'videoDietaryGuidelines', data: []},
      {name: 'DRIS', key: 'videoDRIS', data: []},
    ]
  })
  
  useEffect(()=>{
    console.log(location)
    console.log(router.find(e=> e.path === location.pathname))
    const findObj = router.find(e=> e.path === location.pathname)
    setFindRouter(()=>findObj)
    if(location.state && location.state.tag){
      const tag = location.state.tag;
      const id = location.state.id;
      const findTag = tagData[findObj.key].find(e=> e.key === tag)
      console.log(findTag)
      setSelectTag(()=> findTag)
      setSelectArticle({id})
      // if(id && findTag){
      //   setSelectArticle(findTag.data.find(e=> e.id === id))
      //   setArticleIndex(findTag.data.findIndex(e=> e.id === id))
      // }
    }else{
      // setSelectTag(tagData[findObj.key][0])
      setSelectTag({})
      setSelectArticle({})
      setArticleIndex(0)
    }

  },[location.pathname])

  useEffect(()=>{
    // setSelectTag({})
    getContentDataFn(selectTag?.key)
    console.log(findRouter, selectTag)
  },[findRouter.key])

  useEffect(()=>{
    if(selectTag.key){
      getContentDataFn(selectTag?.key)
      console.log(findRouter, selectTag)
    }
  },[selectTag?.key])

  // 获取文章列表
  const getContentDataFn = async (subType) =>{
    console.log('获取文章列表', findRouter, tagData[findRouter.key])
    if(findRouter.key){
      let res = await Axios('/nutri/list', {
        essayType: findRouter.key,
        essaySubType: subType? subType:'',
        pageSize: 999,
        pageNum: 1
      })
      console.log('获取文章列表', res.data.data)
      if(res.data.status === 200){
        let arr = res.data.data.map(e=>{
          let obj = {
            id: e.id,
            Key: e.essaySubType,
            title: e.essayTitle,
            time: e.crtTm,
            img: e.essayImg,
            data:``
          }
          if(e.essaySubType === 'news'){
            return ({...obj, defaultImg: guoji})
          }else if(e.essaySubType === 'Notice'){
            return ({...obj, defaultImg: hangye})
          }else{
            return ({...obj})
          }
        })
        console.log(arr)
        console.log('findRouter', findRouter)
        console.log('selectTag', selectTag)
        let findTag = []
        if(selectTag?.key){
          tagData[findRouter.key].forEach(e=>{
            if(e.key === selectTag.key){
              e.data = arr
              findTag = e
            }
          })
          console.log(findTag)
          setTagData(tagData)
          setSelectAllData(()=> [])
          setSelectTag(()=> findTag)
        }else{
          setSelectAllData(()=> arr)
        }

        console.log(findTag)
        if(selectArticle.id && findTag.data){
          console.log(findTag.data)
          getContentDetailFn(selectArticle.id)
          setArticleIndex(findTag.data.findIndex(e=> e.id === selectArticle.id))
        }
        console.log(tagData[findRouter.key])
      }
    }
  }
  // 获取文章详情
  const getContentDetailFn = async (id) =>{
    console.log(id)
    if(selectTag.key){
      let res = await Axios('/nutri/info', {id})
      console.log('获取文章详情', res.data.data)
      if(res.data.status === 200 && res.data.data){
        setSelectArticle({
          id: res.data.data.id,
          Key: res.data.data.essaySubType,
          title: res.data.data.essayTitle,
          time: res.data.data.crtTm,
          img: res.data.data.essayImg,
          data: res.data.data.essayData
        })
      }
    }
  }

  return (
    <div>
      <div className='content-box'>
        <div className='breadcrumb-box'>
          <HomeFilled style={{color:'#30790f',marginRight: 10}} />当前位置：
          <span onClick={()=> navigate('/home')}>首页</span>
          {` > `}
          <span 
            onClick={()=> {
              // setSelectTag(tagData[findRouter.key][0])
              let arr = []
              console.log(tagData[findRouter.key])
              tagData[findRouter.key].forEach(e=>{
                if(e.data) {
                  e.data.forEach(E=> E.Key = e.key)
                  arr = arr.concat(e.data)
                }
              })
              setSelectAllData(arr)
              setSelectTag({})
              setSelectArticle({})
              setArticleIndex(0)
            }}
          >{findRouter.name}</span>
        </div>

        <div className='contents'>
          {/* 选择Tag */}
          <div className='content-left'>
            <div className="content-left-title">{findRouter.name}</div>
            <ul className='tag-list'>
              {tagData[findRouter.key]?.map((e,i)=>{
                console.log(e)
                if(e.link || e.link === ''){
                  if(e.link){
                    return (
                      <a key={i} href={e.link} style={{color:'#000'}} target="_blank">
                        <li className={'tag-item'}>{e.name}</li>
                      </a>
                    )
                  }else{
                    return (<li className={'tag-item'} onClick={()=> alert('敬请期待')}>{e.name}</li>)
                  }
                }else if(e.notes){
                  return <li 
                    key={i} 
                    className={selectTag?.name === e.name? 'tag-item action':'tag-item'}
                    onClick={()=> setSelectTag(e)}
                    >{e.name}</li>
                }else{
                  return (
                    <li 
                      key={i}
                      className={selectTag?.name === e.name? 'tag-item action':'tag-item'}
                      onClick={()=> {
                        setSelectTag(e)
                        if(e.name === '考核咨询'){
                          setSelectArticle(e.data[0])
                          setArticleIndex(0)
                        }else{
                          setSelectArticle({})
                          setArticleIndex(0)
                        }
                      }}
                    >
                      {e.name}
                    </li>
                  )
                }
              })}
            </ul>
          </div>
          {/* 内容列表 */}
          <div className='content-right'>
            {/* {selectAllData} */}
            {!selectTag?.name? 
              <div>
                <div style={{
                  borderBottom: '1px solid #000',
                  lineHeight: '30px',
                  fontWeight:900
                }}>{findRouter.name}</div>
                <List
                  size="large"
                  bordered
                  style={{border:'none'}}
                  dataSource={selectAllData}
                  renderItem={(item,i) => {
                    return (
                      <List.Item 
                        key={i}
                        className='content-right-list-item'
                        onClick={()=>{
                          console.log(tagData[findRouter.key], item)
                          const findTag = tagData[findRouter.key].find(e=> e.key === item.Key)
                          console.log(findTag)
                          setSelectTag(findTag)
                          setSelectArticle(item)
                          console.log('findTag',findTag.data)
                          console.log('index',findTag.data.findIndex(e=> e.id === item.id))
                        }}
                      > 
                        <div 
                          className={`list-item-img-box ${item.img? 'Img':'emptyImg'}`} 
                          style={{backgroundImage: `url(${item.img? item.img:item.defaultImg? item.defaultImg:emptyImg})`}}>
                        </div>
                        <div style={{margin:'20px 0 20px 20px'}}>
                          <div style={{
                            width: 540,
                            fontSize: 20,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'inline-block',
                          }}>{item?.title}</div>
                          <div style={{marginRight: 20}}>{item.time? item.time:''}</div>
                        </div>
                      </List.Item>
                    )
                  }}
                  pagination={{ 
                    position:'bottom', 
                    align:'start',
                    pageSize: 8,
                    showQuickJumper: true
                  }}
                />
              </div>
              :
              selectTag?.notes? 
                selectTag.notes
                :
                !selectArticle.title? 
                  <div>
                    <div style={{
                      borderBottom: '1px solid #000',
                      lineHeight: '30px',
                      fontWeight:900
                    }}>{selectTag.name}</div>
                    <List
                      size="large"
                      bordered
                      style={{border:'none'}}
                      dataSource={selectTag.data}
                      renderItem={(item,i) => {
                        return (
                          <List.Item 
                            key={i}
                            className='content-right-list-item'
                            onClick={()=>{
                              console.log('selectTag',selectTag.data)
                              console.log('index',selectTag.data.findIndex(e=> e.id === item.id))
                              getContentDetailFn(item.id)
                              // setSelectArticle(item)
                              setArticleIndex(selectTag.data.findIndex(e=> e.id === item.id))
                            }}
                          >
                            <div 
                              className={`list-item-img-box ${item.img? 'Img':'emptyImg'}`} 
                              style={{backgroundImage: `url(${item.img? item.img:item.defaultImg? item.defaultImg:emptyImg})`}}>
                            </div>
                            <div style={{margin:'20px 0 20px 20px'}}>
                              <div style={{
                                width: 540,
                                fontSize: 20,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: 'inline-block',
                              }}>{item?.title}</div>
                              <div style={{marginRight: 20}}>{item.time? item.time:''}</div>
                            </div>
                          </List.Item>
                        )
                      }}
                      pagination={{ 
                        position:'bottom', 
                        align:'start',
                        pageSize: 8,
                        showQuickJumper: true
                      }}
                    />
                  </div>
                  :
                  <div className='detail-box'>
                    <div style={{
                      borderBottom: '1px solid #000',
                      lineHeight: '30px',
                      fontWeight:900
                    }}>{selectArticle.title}</div>
                    {/* <div className='contentadddate'>{selectArticle.time}</div> */}
                    <div 
                      style={{paddingBottom: 40, textAlign:'center'}} 
                      dangerouslySetInnerHTML={{ __html: selectArticle.data }} />
                    {/* 翻页 */}
                    {articleIndex+1 > selectTag.data?.length-1? 
                        ''
                        :
                        <div style={{paddingBottom: 10}}>
                          上一篇：
                            <span 
                              style={{cursor: 'pointer'}}
                              onClick={()=>{
                                console.log(selectTag)
                                if(selectTag.data){
                                  getContentDetailFn(selectTag.data[articleIndex+1].id)
                                  // setSelectArticle(selectTag.data[articleIndex+1])
                                  setArticleIndex(articleIndex+1)
                                }
                              }}
                            >{selectTag.data[articleIndex+1].title}</span>  
                        </div>
                    }
                    {articleIndex-1 < 0?
                      ''
                      :
                      <div style={{paddingBottom: 30}}>
                        下一篇：
                          <span
                            style={{cursor: 'pointer'}}
                            onClick={()=>{
                              if(selectTag.data){
                                getContentDetailFn(selectTag.data[articleIndex-1].id)
                                // setSelectArticle(selectTag.data[articleIndex-1])
                                setArticleIndex(articleIndex-1)
                              }
                            }}
                          >{selectTag.data[articleIndex-1].title}</span>
                      
                      </div> 
                    }
                  </div>
            }
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Content