import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Layout, ConfigProvider } from 'antd';
import HeaderCom from './components/header/header'
import FooterCom from './components/footer/footer'

import Home from './pages/home/home'

import { router } from './router'

import zhCN from 'antd/locale/zh_CN';

const { Footer, Content } = Layout;

const layoutStyle = {
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
  backgroundColor: '#fff',
};

const contentStyle = {
  minHeight: 120,
  fontSize: 18,
  color: '#000',
  textAlign: 'left',
  backgroundColor: '#fff',
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> 
  
  <ConfigProvider locale={zhCN}>
    <HashRouter>
      <Layout style={layoutStyle}>
        <HeaderCom />
        <Content style={contentStyle}>
          <Routes>
            <Route exact path='/' element={<Home/>} />
            {router.map((e,i)=>{
              return <Route key={i} exact path={e.path} element={e.component} />
            })}
            {/* <Route exact path='/home' element={<Home/>} /> */}
          </Routes>
        </Content>
        <FooterCom />
      </Layout>
    </HashRouter>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
