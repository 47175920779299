import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Carousel } from 'antd';
import banner1 from '../../images/bannerImg/1.png'
import banner2 from '../../images/bannerImg/2.png'
import banner3 from '../../images/bannerImg/3.png'

import tools1 from '../../images/toolsImg/tools1.png'
import tools2 from '../../images/toolsImg/tools2.png'
import tools3 from '../../images/toolsImg/tools3.png'
import tools4 from '../../images/toolsImg/tools4.png'


import left from '../../images/bannerImg/left.png'
import right from '../../images/bannerImg/right.png'

import yyjie from '../../images/QRCodeImg/yyjie.png'
import haoyy from '../../images/QRCodeImg/haoyy.png'
import fnjh from '../../images/QRCodeImg/fnjh.png'
import dyj from '../../images/QRCodeImg/dyj.jpg'

import Axios from '../../components/BaseRequest.ts'

import { 
  DRIs,
  NoticeData, 
  nutritionSciencePopularization,
  technicalStandard,
  dietaryGuidelines,
  institutionImg, 
  linkListData,
  videoDRIS,
  videoDietaryGuidelines
} from '../../components/mokeData'

import './index.less'

const { next, prev } = Carousel;
const Home = () =>{
  const navigate  = useNavigate()
  const carouselRef = useRef(null);
  // const scrollBox = useRef(null);

  const bannerData = [{
    link: 'https://www.cnsedu.net/',
    color: 'rgb(255,255,255)',
    img: banner1,
    imgStyle: {maxWidth: 1160},
  },{
    link: 'https://www.crdietitian.org/',
    color: 'rgb(78,192,193)',
    img: banner2,
    imgStyle: {maxWidth: 1160},
  },{
    link: 'https://www.cnsedu.net/skill/index',
    color: 'rgb(255,214,102)',
    img: banner3,
    imgStyle: {maxWidth: 1160},
  },]

  const [noticeData, setNoticeData] = useState([
    {
      index: 2,
      title: '行业动态',
      key: 'newsAndAnnouncement',
      subKey: "Notice",
      link: '/newsAndAnnouncement',
      // link: '/policyStandards',
      // list: technicalStandard,
      list: [],
    },{
      index: 1,
      title: '国际资讯',
      key: 'newsAndAnnouncement',
      subKey: "news",
      link: '/newsAndAnnouncement',
      // list: NoticeData,
      list: [],
    },
    // {
    //   index: 3,
    //   title: '专业提升',
    //   key: 'abilityPromotion',
    //   link: '/abilityPromotion',
    //   // list: nutritionSciencePopularization,
    //   list: [],
    // },
    // {
    //   index: 5,
    //   title: '营养工具',
    //   key: 'nutritionTools',
    //   link: '/nutritionTools',
    //   // list: DRIs,
    //   list: [],
    // }
  ])

  const nutritionToolsBtn = [{
    title: '营养数据信息资源库',
    img: tools1,
    link: ''
  },{
    title: '配餐工县',
    img: tools4,
    link: 'https://www.dyhomedr.com/#/user/public'
  },{
    title: '膳食指南工具包',
    img: tools3,
    link: 'https://www.cnsedu.net/tool/toolkit'
  },{
    title: '营养与健康信息共享平台',
    img: tools2,
    link: 'http://www.nutri-info.org.cn/',
  }]
  
  const [videoData, setVideoData] = useState([{
    title: 'DRIS',
    key: 'videoDRIS',
    link: '/videoZone',
    // list: videoDRIS,
    list: [],
  },{
    title: '膳食指南',
    key: 'videoDietaryGuidelines',
    link: '/videoZone',
    // list: videoDietaryGuidelines,
    list: [],
  }])

  useEffect(()=>{
    getHomeNoticeDataFn()
    
    // let num = 0
    // let timer = null
    // timer = setInterval(()=>{
    //   num ++ 
    //   scrollBox.current.scrollTop = num
    //   if(num === ((institutionImg.length/3)/2)*92){
    //     // console.log(num)
    //     let splitData = institutionImg.splice(0,institutionImg.length/2)
    //     institutionImg.unshift(...splitData)
    //     scrollBox.current.scrollTop = 0
    //     num = 0
    //   }
    // },50);
    // return () => {
    //   clearTimeout(timer);
    // };
  },[])
  
  // 获取文章列表
  const getHomeNoticeDataFn = async () =>{
    // 文章类型,1-新闻公告,2-法规标准,3-能力提升,4-公共营养师,5-营养工具,6-视频专区,7-机构展示,8-友情链接
    let res = await Axios('/nutri/homeList', {
      essayType: '',
      essaySubType: '',
    })
    console.log('获取文章列表', res.data.data)
    if(res.data.status === 200){
      let copyNotice = JSON.parse(JSON.stringify(noticeData))
      copyNotice.forEach(e=>{
        console.log(res.data.data[e.key], e.key)
        e.list = res.data.data[e.key].map(E=>{
          if(e.subKey === E.essaySubType){
            return ({
              id: E.id,
              Key: E.essaySubType,
              title: E.essayTitle,
              time: E.crtTm,
              img: E.essayImg,
              data:``
            })
          }
        })
      })
      setNoticeData(()=> copyNotice)

      let copyVideoData = JSON.parse(JSON.stringify(videoData))
      res.data.data['videoZone'].forEach(e=>{
        let index = copyVideoData.findIndex(E=> E.key === e.essaySubType)
        console.log(index)
        copyVideoData[index].list.push({
          id: e.id,
          Key: e.essaySubType,
          title: e.essayTitle,
          time: e.crtTm,
          img: e.essayImg,
          data: ``
        })
      })
      setVideoData(()=> copyVideoData)
      console.log(copyVideoData)
    }
  }

  return (
    <div>
      {/* 轮播图 */}
      <div className='Carousel-box'>
        <Carousel autoplay autoplaySpeed={50000} ref={carouselRef}>
          {bannerData.map((e,i)=>{
            return (
              <div key={i}>
                <a 
                  href={e.link}
                  style={{
                    textAlign:'center', 
                    color:'#000', 
                    display: 'flex', 
                    justifyContent: 'center',
                    backgroundColor: e.color
                  }} 
                  target="_blank"
                >
                  <img style={e.imgStyle} src={e.img} alt=''/>
                </a>
              </div>
            )
          })}
        </Carousel>
        <div 
          className='Carousel-button Carousel-left'
          onClick={()=> carouselRef.current.next()}
        ></div>
        <div 
          className='Carousel-button Carousel-right' 
          onClick={()=> carouselRef.current.prev()}
        ></div>
      </div>
      

      <div className='home-content-box'>
        <div className='home-content'>
          {/* 公告 */}
          <div className='home-content-left'>
            {noticeData.map((e,i)=>{
              console.log(e)
              return(
                <div className='left-item' key={i}>
                  <div className='item-top'>
                    <div className="item-title">
                      {e.title}
                    </div>
                    <div 
                      className="item-link"
                      onClick={()=> navigate(e.link)}
                    >{'更多>>'}</div>
                  </div>
                  <ul className='item-list'>
                    {e.list.filter(e=>e).map((E,I)=>{
                      if(E && I<5){
                        return (
                          <li 
                            key={I} 
                            className='item-li' 
                            onClick={()=>{
                              console.log(e.link, { state: {tag: E.Key, id: E.id, index: I} })
                              navigate(e.link, { state: {tag: E.Key, id: E.id, index: I} })
                            }}
                          >
                            <div className="li-name">{E.title}</div>
                            <div className="li-time">{E.time.split(' ')[0]}</div>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
          {/* 视频展示，机构展示 */}
          <div className='home-content-right'>
            <div className="right-item">
              <div className='item-top'>
                <div className="item-title">营养师工具</div>
                <div 
                  className="item-link"
                  onClick={()=> navigate('/nutritionTools')}
                >{'更多>>'}</div>
              </div>
              <div className="item-list">
                {nutritionToolsBtn.map((e,i)=>{
                  return (
                    <div className="item-li" key={i}>
                      <div 
                        className="li-img" 
                        onClick={()=>{
                          if(e.link){ 
                            window.open(e.link)
                            // navigate(e.link)
                          }else{
                            alert('敬请期待')
                          }
                        }}
                      >
                        <img src={e.img} alt="" />
                      </div>
                      <span 
                        className="li-name"
                        onClick={()=>{
                          if(e.link){
                            window.open(e.link)
                            // navigate(e.link)
                          }else{
                            console.log(e.link)
                            alert('敬请期待')
                          }
                        }}
                      >{e.title}</span>
                    </div>
                  )
                })}
                {/* {videoData.map((e,i)=>{
                  return e.list.map((E,I)=>{
                    if(E){
                      return (
                        <div className="item-li" key={i+'-'+I}>
                          <div 
                            className="li-img" 
                            style={{backgroundImage: `url(${E.img})`}}
                            onClick={()=>{
                              navigate(e.link, { state: {tag: e.key, id: E.id, index: I} })
                            }}
                          ></div>
                          <span 
                            className="li-name"
                            onClick={()=>{
                              navigate(e.link, { state: {tag: e.key, id: E.id, index: I} })
                            }}
                          >{E.title}</span>
                        </div>
                      )
                    }
                  })
                })} */}
              </div>
            </div>
            
            {/* <div className="right-item">
              <div className="item-top">机构展示</div>
              <div ref={scrollBox} className="item-list institutionImg" >
                {institutionImg.map((e,i)=>{
                  return (
                    <div className="item-li" key={i}>
                      <div className="li-img" style={{backgroundImage: `url(${e.img})`}}></div>
                      <div className="li-name">{e.title}</div>
                    </div>
                  )
                })}
              </div>
            </div> */}
          </div>
        </div>
        
        <div className='link-list-title'>友情链接</div>
        <div className='link-list'>
          {linkListData.map((e,i)=>{
            if(e.link){
              return (
                <a href={e.link} target="_blank" key={i}>
                  <img className="link-list-img" src={e.img} style={e.style} alt=''/>
                </a>
              )
            }else{
              return (
                <img className="link-list-img" key={i} src={e.img} style={e.style} alt='' onClick={()=> alert('敬请期待')}/>
              )
            }
            // return (
              // <div 
              //   className="link-list-img" 
              //   key={i}
              //   style={{backgroundImage: `url(${e.img})`}}
              // ></div>
            // )
          })}
        </div>
      </div>
      

 
 
      {/* 二维码 */}
      {/* <div className='Affix-box'>
        <div className='QRCode-item'>
          <img src={yyjie} alt="" />
          <div className='QRCode-name'>中国营养界官微</div>
        </div>
        <div className='QRCode-item'>
          <img src={haoyy} alt="" />
          <div className='QRCode-name'>中国好营养科普官微</div>
        </div>
        <div className='QRCode-item'>
          <img src={fnjh} alt="" />
          <div className='QRCode-name'>营养师赋能计划</div>
        </div>
        <div className='QRCode-item'>
          <img src={dyj} alt="" />
          <div className='QRCode-name'>大营家营养师</div>
        </div>
      </div> */}
    </div>
  )
}

export default Home